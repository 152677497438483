import React from "react";
import ProdLayout from "../../prodlayout";
import pi1 from "./p1.jpg";
import pi2 from "./p2.jpg";
import pi3 from "./p3.jpg";
import pi4 from "./p4.jpg";
const data = {
  images: [pi1, pi2, pi3, pi4],
  badge: "Solid",
  sku: "SHW01",
  availability: "In stock",
  title: "OCSO Solid Men's shorts SHW01",
  mrpPrice: "1190",
  sellPrice: "499",
  disc:
    "Including a punctured plan at the back for improved ventilation is this pair of Blue and Grey shorts from ocso. Intended for wellness cognizant men, these shorts likewise highlight an elasticated belt that guarantees a superb hold and fit throughout the day. These agreeable polyester shorts can be best worn with a T-shirt and sports shoes.",
};
export default () => {
  return <ProdLayout data={data} />;
};
